const axios = require('axios');
const qery_stringify = require('qs').stringify;
const md5 = require('js-md5')

class Client {
    static PROTOCOL_VERSION = "V3.0.0";
    static appId = "f9b3528877c94d5c9c5af32245db46ef";
    static appSecret = "0cf75926606049a3937f56b0373b99fb";
    static device = "pc";//pc/pcf
    static language = "CN";// CN/US
    constructor() {
        this.token = "";
        this.headers = {
            "Accept": "application/json",
            "XX-IS-CN": 1, "XX-Version": Client.PROTOCOL_VERSION,
            "XX-Device-Type": Client.device, "XX-LANGUAGE": Client.language
        };
        this.http = axios.create({
            headers: this.headers,
            baseURL: "https://cloud-platform.anycubic.com/p/p/workbench/api",
            timeout: 60000
        });
        this.http.interceptors.request.use(
            (config) => {
                // 一般会请求拦截里面加token，用于后端的验证
                const token = localStorage.getItem("token")
                if (token) {
                    config.headers["XX-Token"] = token;
                }
                // console.log(JSON.stringify(config));
                // console.log(config.url);
                return config
            },
            (err) => {
                // 请求错误，这里可以用全局提示框进行提示
                return Promise.reject(err)
            },
        );
    }
    get isLogin() {
        return this.token != "";
    }
    async login(username, password) {
        const buffer = { "username": username, "password": password, "mobile_country_code": "CN" };
        const data = await this.post("/user/public/login", buffer, this.defaultHeader(0))
        if (data.code === 1) {
            this.token = data.data.token;
            localStorage.setItem("token", this.token);
            return true;
        }
        return false;
    }
    async priterList(type) {
        const buffer = { "page": 1, "limit": 1, "type": type };
        const data = await this.post("/work/printer/getPrinters", buffer, this.defaultHeader(0));
        if (data.code == 1 && data.data.length > 0) {
            return { key: data.data[0].key, id: data.data[0].id };
        }
        return {};

    }

    async loadKVSInfo(printerID) {
        const buffer = { order_id: 1001, printer_id: printerID, project_id: 0, data: null };
        const data = await this.post("/work/operation/sendOrder", buffer, this.defaultHeader(0));
        if (data.code == 1) {
            return data.data.token;
        }
        return {};
    }

    get uuid() {
        const temp_url = URL.createObjectURL(new Blob());
        const uid = temp_url.toString();
        URL.revokeObjectURL(temp_url);
        return uid.substr(uid.lastIndexOf("/") + 1);
    }
    make_signature(timems, Nonce) {
        const data = Client.appId + timems + Client.PROTOCOL_VERSION + Client.appSecret + Nonce + Client.appId;
        return md5.md5(data);
    }

    defaultHeader(type) {
        const times = (new Date()).valueOf();
        const nonce = this.uuid;
        let ct = "";
        if (type == 1) {
            ct = "application/json;charset=UTF-8";
        } else {
            ct = "application/x-www-form-urlencoded;charset=UTF-8";
        }
        return {
            headers: {
                "XX-Timestamp": times, "XX-Nonce": nonce,
                "XX-Signature": this.make_signature(times.toString(), nonce),
                "Content-Type": ct
            }
        };
    }
    async post(url, data, config) {
        if (data) {
            data = qery_stringify(data);
        }

        const response = await this.http.post(url, data, config);
        if (response.status == 200) {
            return response.data;
        } else {
            return { code: 0 }
        }

    }
}
export const client = new Client();