<template>
  <HelloWorld v-if="loginState" />

  <div v-else class="row center body">
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">用户名:</el-text></el-col
      >
      <el-col :span="15">
        <el-input v-model="username" placeholder="用户名"
      /></el-col>
    </el-row>
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">密码:</el-text></el-col
      >
      <el-col :span="15">
        <el-input v-model="password" placeholder="密码"
      /></el-col>
    </el-row>

    <el-row align="middle" justify="center" :gutter="3">
      <el-col :span="3"
        ><el-button type="primary" @click="login">登录</el-button></el-col
      >
    </el-row>
  </div>
</template>

<script setup>
import HelloWorld from "./components/HelloWorld.vue";
import { ref } from "vue";
import { client } from "./api/client";

const username = ref("liuchunlin@anycubic.com");
const password = ref("q1w2e3r4t5");
const loginState = ref(false);
async function login() {
  const state = await client.login(username.value, password.value);
  if (state) {
    client.priterList().then((info) => {
      localStorage.setItem("key", info.key);
      localStorage.setItem("id", info.id);
    });
    loginState.value = state;
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.row {
  line-height: 2;
}
</style>
