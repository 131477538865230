<template>
  <div class="row center body">
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">secretId:</el-text></el-col
      >
      <el-col :span="15">
        <el-input disabled v-model="secretId" placeholder="secretId"
      /></el-col>
    </el-row>
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">secretKey:</el-text></el-col
      >
      <el-col :span="15">
        <el-input disabled v-model="secretKey" placeholder="secretKey"
      /></el-col>
    </el-row>
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">token:</el-text></el-col
      >
      <el-col :span="15">
        <el-input disabled v-model="token" placeholder="token"
      /></el-col>
    </el-row>
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">region:</el-text></el-col
      >
      <el-col :span="15">
        <el-input disabled v-model="region" placeholder="region"
      /></el-col>
    </el-row>
    <el-row :gutter="4">
      <el-col :span="8"
        ><el-text size="large" class="label">channelName:</el-text></el-col
      >
      <el-col :span="15">
        <el-input disabled v-model="channelName" placeholder="channelName"
      /></el-col>
    </el-row>
    <el-row align="middle" justify="center" :gutter="3">
      <el-col :span="3"
        ><el-button type="primary" @click="loadKVSInfo">加载</el-button></el-col
      >
      <el-col :span="3"
        ><el-button type="primary" @click="startVideo">开始</el-button></el-col
      >

      <el-col :span="3"
        ><el-button type="primary">停止</el-button></el-col
      ></el-row
    >
  </div>
  <el-divider class="body div-video" />

  <div class="body div-video">
    <video
      class="remote-view"
      id="master_video"
      autoplay
      playsinline
      controls
    ></video>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { client } from "../api/client";
import { startViewer } from "../api/aws_sdk";
const channelName = ref("");
const secretId = ref("");
const secretKey = ref("");
const token = ref("");
const region = ref("");

onMounted(() => {
  const id = localStorage.getItem("key");
  if (id) {
    channelName.value = id;
  }
});

async function loadKVSInfo() {
  const id = localStorage.getItem("id");
  if (id == 0) {
    return;
  }
  const response = await client.loadKVSInfo(id);
  if (response) {
    secretId.value = response.tmpSecretId;
    secretKey.value = response.tmpSecretKey;
    token.value = response.sessionToken;
    region.value = response.region;
  }
}
function getRandomClientId() {
  return Math.random().toString(36).substring(2).toUpperCase();
}
function getFormValues() {
  return {
    accessKeyId: secretId.value,
    channelName: channelName.value,
    clientId: getRandomClientId(),
    region: region.value,
    secretAccessKey: secretKey.value,
    sessionToken: token.value,

    acceptHostCandidates: true,
    acceptPrflxCandidates: true,
    acceptRelayCandidates: true,
    acceptSrflxCandidates: true,
    acceptTcpCandidates: true,
    acceptUdpCandidates: true,
    enableDQPmetrics: false,
    endpoint: null,
    forceSTUN: false,
    forceTURN: false,
    fullscreen: false,
    ingestMedia: false,
    natTraversalDisabled: false,
    openDataChannel: false,
    sendAudio: false,
    sendHostCandidates: true,
    sendPrflxCandidates: true,
    sendRelayCandidates: true,
    sendSrflxCandidates: true,
    sendTcpCandidates: true,
    sendUdpCandidates: true,
    sendVideo: false,
    showJSSButton: false,
    streamName: "",
    useTrickleICE: true,
    widescreen: true,
  };
}
async function startVideo() {
  console.log("start");
  const viewer = document.getElementById("master_video");
  await startViewer(viewer, getFormValues(), (event) => {
    console.log(event.data);
  });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.label {
  float: right;
}
.row {
  line-height: 2;
}
.test {
  background: red;
}
.center {
  text-align: center;
}
.body {
  width: 80%;
  margin: 0 auto;
}
.div-video {
  margin-top: 20px;
}
.remote-view {
  width: 800px;
  height: 600px;
}
</style>
